import * as d3Shape from "victory-vendor/d3-shape";

var defined = function (d) {
  var y = d._y1 !== undefined ? d._y1 : d._y;
  return y !== null && y !== undefined && d._y0 !== null;
};

var getXAccessor = function (scale) {
  return function (d) {
    return scale.x(d._x1 !== undefined ? d._x1 : d._x);
  };
};

var getYAccessor = function (scale) {
  return function (d) {
    return scale.y(d._y1 !== undefined ? d._y1 : d._y);
  };
};

var getAngleAccessor = function (scale) {
  return function (d) {
    var x = scale.x(d._x1 !== undefined ? d._x1 : d._x);
    return -1 * x + Math.PI / 2;
  };
};

var toNewName = function (interpolation) {
  // d3 shape changed the naming scheme for interpolators from "basis" -> "curveBasis" etc.
  var capitalize = function (s) {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  return "curve".concat(capitalize(interpolation));
};

var toNewNameClosed = function (interpolation) {
  return "".concat(toNewName(interpolation), "Closed");
};

export var getInterpolationFunction = function (props) {
  var interpolation = props.interpolation;

  if (typeof interpolation === "function") {
    return interpolation;
  }

  if (typeof interpolation === "string") {
    var polar = props.polar,
        _props$openCurve = props.openCurve,
        openCurve = _props$openCurve === void 0 ? !polar : _props$openCurve;
    var interpolationName = !openCurve ? toNewNameClosed(interpolation) : toNewName(interpolation);
    return d3Shape[interpolationName];
  }

  return d3Shape.curveLinear;
};
export var getLineFunction = function (props) {
  var polar = props.polar,
      scale = props.scale,
      horizontal = props.horizontal;
  return polar ? d3Shape.lineRadial().defined(defined).curve(getInterpolationFunction(props)).angle(getAngleAccessor(scale)).radius(getYAccessor(scale)) : d3Shape.line().defined(defined).curve(getInterpolationFunction(props)).x(horizontal ? getYAccessor(scale) : getXAccessor(scale)).y(horizontal ? getXAccessor(scale) : getYAccessor(scale));
};