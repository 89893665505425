export default {
  "hljs-comment": {
    "color": "#65737e"
  },
  "hljs-quote": {
    "color": "#65737e"
  },
  "hljs-variable": {
    "color": "#bf616a"
  },
  "hljs-template-variable": {
    "color": "#bf616a"
  },
  "hljs-tag": {
    "color": "#bf616a"
  },
  "hljs-name": {
    "color": "#bf616a"
  },
  "hljs-selector-id": {
    "color": "#bf616a"
  },
  "hljs-selector-class": {
    "color": "#bf616a"
  },
  "hljs-regexp": {
    "color": "#bf616a"
  },
  "hljs-deletion": {
    "color": "#bf616a"
  },
  "hljs-number": {
    "color": "#d08770"
  },
  "hljs-built_in": {
    "color": "#d08770"
  },
  "hljs-builtin-name": {
    "color": "#d08770"
  },
  "hljs-literal": {
    "color": "#d08770"
  },
  "hljs-type": {
    "color": "#d08770"
  },
  "hljs-params": {
    "color": "#d08770"
  },
  "hljs-meta": {
    "color": "#d08770"
  },
  "hljs-link": {
    "color": "#d08770"
  },
  "hljs-attribute": {
    "color": "#ebcb8b"
  },
  "hljs-string": {
    "color": "#a3be8c"
  },
  "hljs-symbol": {
    "color": "#a3be8c"
  },
  "hljs-bullet": {
    "color": "#a3be8c"
  },
  "hljs-addition": {
    "color": "#a3be8c"
  },
  "hljs-title": {
    "color": "#8fa1b3"
  },
  "hljs-section": {
    "color": "#8fa1b3"
  },
  "hljs-keyword": {
    "color": "#b48ead"
  },
  "hljs-selector-tag": {
    "color": "#b48ead"
  },
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "background": "#2b303b",
    "color": "#c0c5ce",
    "padding": "0.5em"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};