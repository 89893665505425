export default {
  "hljs-comment": {
    "color": "#7285b7"
  },
  "hljs-quote": {
    "color": "#7285b7"
  },
  "hljs-variable": {
    "color": "#ff9da4"
  },
  "hljs-template-variable": {
    "color": "#ff9da4"
  },
  "hljs-tag": {
    "color": "#ff9da4"
  },
  "hljs-name": {
    "color": "#ff9da4"
  },
  "hljs-selector-id": {
    "color": "#ff9da4"
  },
  "hljs-selector-class": {
    "color": "#ff9da4"
  },
  "hljs-regexp": {
    "color": "#ff9da4"
  },
  "hljs-deletion": {
    "color": "#ff9da4"
  },
  "hljs-number": {
    "color": "#ffc58f"
  },
  "hljs-built_in": {
    "color": "#ffc58f"
  },
  "hljs-builtin-name": {
    "color": "#ffc58f"
  },
  "hljs-literal": {
    "color": "#ffc58f"
  },
  "hljs-type": {
    "color": "#ffc58f"
  },
  "hljs-params": {
    "color": "#ffc58f"
  },
  "hljs-meta": {
    "color": "#ffc58f"
  },
  "hljs-link": {
    "color": "#ffc58f"
  },
  "hljs-attribute": {
    "color": "#ffeead"
  },
  "hljs-string": {
    "color": "#d1f1a9"
  },
  "hljs-symbol": {
    "color": "#d1f1a9"
  },
  "hljs-bullet": {
    "color": "#d1f1a9"
  },
  "hljs-addition": {
    "color": "#d1f1a9"
  },
  "hljs-title": {
    "color": "#bbdaff"
  },
  "hljs-section": {
    "color": "#bbdaff"
  },
  "hljs-keyword": {
    "color": "#ebbbff"
  },
  "hljs-selector-tag": {
    "color": "#ebbbff"
  },
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "background": "#002451",
    "color": "white",
    "padding": "0.5em"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};