// Generated by Framer (ea5112a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XWkbntxf7", "eKHLujcHq"];

const variantClassNames = {eKHLujcHq: "framer-v-ispxq4", XWkbntxf7: "framer-v-1l27729"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "XWkbntxf7", "Variant 2": "eKHLujcHq"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "XWkbntxf7", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XWkbntxf7", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1spev3 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 100)
})

useOnVariantChange(baseVariant, {default: onAppear1spev3})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-DZCwa", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1l27729", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"XWkbntxf7"} ref={ref} style={{backgroundColor: "rgb(102, 244, 143)", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} transition={transition} variants={{eKHLujcHq: {backgroundColor: "rgba(102, 244, 143, 0.5)"}}} {...addPropertyOverrides({eKHLujcHq: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-DZCwa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DZCwa .framer-15j11vh { display: block; }", ".framer-DZCwa .framer-1l27729 { height: 16px; overflow: hidden; position: relative; width: 16px; will-change: transform; }", ".framer-DZCwa.framer-v-ispxq4 .framer-1l27729 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 24px); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"eKHLujcHq":{"layout":["fixed","fixed"]}}}
 */
const FramerwfkoyTelF: React.ComponentType<Props> = withCSS(Component, css, "framer-DZCwa") as typeof Component;
export default FramerwfkoyTelF;

FramerwfkoyTelF.displayName = "Status";

FramerwfkoyTelF.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerwfkoyTelF, {variant: {options: ["XWkbntxf7", "eKHLujcHq"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerwfkoyTelF, [])