var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { cssValue, parseLengthAndUnit } from "./helpers/unitConverter";
import { createAnimation } from "./helpers/animation";
var circle = createAnimation("CircleLoader", "0% {transform: rotate(0deg)} 50% {transform: rotate(180deg)} 100% {transform: rotate(360deg)}", "circle");
function CircleLoader(_a) {
    var _b = _a.loading, loading = _b === void 0 ? true : _b, _c = _a.color, color = _c === void 0 ? "#000000" : _c, _d = _a.speedMultiplier, speedMultiplier = _d === void 0 ? 1 : _d, _e = _a.cssOverride, cssOverride = _e === void 0 ? {} : _e, _f = _a.size, size = _f === void 0 ? 50 : _f, additionalprops = __rest(_a, ["loading", "color", "speedMultiplier", "cssOverride", "size"]);
    var wrapper = __assign({ display: "inherit", position: "relative", width: cssValue(size), height: cssValue(size) }, cssOverride);
    var style = function (i) {
        var _a = parseLengthAndUnit(size), value = _a.value, unit = _a.unit;
        return {
            position: "absolute",
            height: "".concat(value * (1 - i / 10)).concat(unit),
            width: "".concat(value * (1 - i / 10)).concat(unit),
            borderTop: "1px solid ".concat(color),
            borderBottom: "none",
            borderLeft: "1px solid ".concat(color),
            borderRight: "none",
            borderRadius: "100%",
            transition: "2s",
            top: "".concat(i * 0.7 * 2.5, "%"),
            left: "".concat(i * 0.35 * 2.5, "%"),
            animation: "".concat(circle, " ").concat(1 / speedMultiplier, "s ").concat((i * 0.2) / speedMultiplier, "s infinite linear"),
        };
    };
    if (!loading) {
        return null;
    }
    return (React.createElement("span", __assign({ style: wrapper }, additionalprops),
        React.createElement("span", { style: style(0) }),
        React.createElement("span", { style: style(1) }),
        React.createElement("span", { style: style(2) }),
        React.createElement("span", { style: style(3) }),
        React.createElement("span", { style: style(4) })));
}
export default CircleLoader;
