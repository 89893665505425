import { useCallback } from 'react';
import { useInstantSearchContext } from "../lib/useInstantSearchContext.js";
import { useIsomorphicLayoutEffect } from "../lib/useIsomorphicLayoutEffect.js";
import { useSearchResults } from "../lib/useSearchResults.js";
import { useSearchState } from "../lib/useSearchState.js";
export function useInstantSearch() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    catchError = _ref.catchError;
  var search = useInstantSearchContext();
  var _useSearchState = useSearchState(),
    uiState = _useSearchState.uiState,
    setUiState = _useSearchState.setUiState,
    indexUiState = _useSearchState.indexUiState,
    setIndexUiState = _useSearchState.setIndexUiState;
  var _useSearchResults = useSearchResults(),
    results = _useSearchResults.results,
    scopedResults = _useSearchResults.scopedResults;
  var use = useCallback(function () {
    for (var _len = arguments.length, middlewares = new Array(_len), _key = 0; _key < _len; _key++) {
      middlewares[_key] = arguments[_key];
    }
    search.use.apply(search, middlewares);
    return function () {
      search.unuse.apply(search, middlewares);
    };
  }, [search]);
  var refresh = useCallback(function () {
    search.refresh();
  }, [search]);
  useIsomorphicLayoutEffect(function () {
    if (catchError) {
      var onError = function onError() {};
      search.addListener('error', onError);
      return function () {
        return search.removeListener('error', onError);
      };
    }
    return function () {};
  }, [search, catchError]);
  return {
    results: results,
    scopedResults: scopedResults,
    uiState: uiState,
    setUiState: setUiState,
    indexUiState: indexUiState,
    setIndexUiState: setIndexUiState,
    use: use,
    refresh: refresh,
    status: search.status,
    error: search.error
  };
}