import { DestroyBounds } from "./DestroyBounds";
import { Split } from "./Split";
export class Destroy {
    constructor() {
        this.bounds = new DestroyBounds();
        this.mode = "none";
        this.split = new Split();
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.mode) {
            this.mode = data.mode;
        }
        if (data.bounds) {
            this.bounds.load(data.bounds);
        }
        this.split.load(data.split);
    }
}
