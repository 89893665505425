export default {
  "hljs-comment": {
    "color": "#776977"
  },
  "hljs-quote": {
    "color": "#776977"
  },
  "hljs-variable": {
    "color": "#ca402b"
  },
  "hljs-template-variable": {
    "color": "#ca402b"
  },
  "hljs-attribute": {
    "color": "#ca402b"
  },
  "hljs-tag": {
    "color": "#ca402b"
  },
  "hljs-name": {
    "color": "#ca402b"
  },
  "hljs-regexp": {
    "color": "#ca402b"
  },
  "hljs-link": {
    "color": "#ca402b"
  },
  "hljs-selector-id": {
    "color": "#ca402b"
  },
  "hljs-selector-class": {
    "color": "#ca402b"
  },
  "hljs-number": {
    "color": "#a65926"
  },
  "hljs-meta": {
    "color": "#a65926"
  },
  "hljs-built_in": {
    "color": "#a65926"
  },
  "hljs-builtin-name": {
    "color": "#a65926"
  },
  "hljs-literal": {
    "color": "#a65926"
  },
  "hljs-type": {
    "color": "#a65926"
  },
  "hljs-params": {
    "color": "#a65926"
  },
  "hljs-string": {
    "color": "#918b3b"
  },
  "hljs-symbol": {
    "color": "#918b3b"
  },
  "hljs-bullet": {
    "color": "#918b3b"
  },
  "hljs-title": {
    "color": "#516aec"
  },
  "hljs-section": {
    "color": "#516aec"
  },
  "hljs-keyword": {
    "color": "#7b59c0"
  },
  "hljs-selector-tag": {
    "color": "#7b59c0"
  },
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "background": "#f7f3f7",
    "color": "#695d69",
    "padding": "0.5em"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};