// Generated by Framer (ea5112a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["extZH8mqE", "E_NupjTuf"];

const variantClassNames = {E_NupjTuf: "framer-v-bodyu", extZH8mqE: "framer-v-1qdk16p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "extZH8mqE", "Variant 2": "E_NupjTuf"};

const transitions = {default: {damping: 11, delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}, E_NupjTuf: {damping: 11, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tint?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "extZH8mqE", tint: CyE7CB3gO = "var(--token-265a51b5-06ea-48da-a42e-3d51fcd0756d, rgb(0, 153, 255)) /* {\"name\":\"Blue\"} */", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "extZH8mqE", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1jlj6wa = activeVariantCallback(async (...args) => {
await delay(() => setVariant("E_NupjTuf"), 1000)
})

const onAppearhj530b = activeVariantCallback(async (...args) => {
await delay(() => setVariant("extZH8mqE"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1jlj6wa, E_NupjTuf: onAppearhj530b})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-RAJLp", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1qdk16p", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"extZH8mqE"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({E_NupjTuf: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1daot1g"} layoutDependency={layoutDependency} layoutId={"mX4BiqD3a"} style={{backgroundColor: CyE7CB3gO, borderBottomLeftRadius: 17, borderBottomRightRadius: 17, borderTopLeftRadius: 17, borderTopRightRadius: 17, opacity: 0.1}} transition={transition} variants={{E_NupjTuf: {borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, opacity: 0.2}}}/><motion.div className={"framer-ko2x01"} layoutDependency={layoutDependency} layoutId={"K7zX3J17T"} style={{backgroundColor: CyE7CB3gO, borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, opacity: 0.5}} transition={transition} variants={{E_NupjTuf: {opacity: 1}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-RAJLp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RAJLp .framer-1uo78aa { display: block; }", ".framer-RAJLp .framer-1qdk16p { height: 16px; overflow: visible; position: relative; width: 16px; }", ".framer-RAJLp .framer-1daot1g { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); left: calc(50.57471264367819% - 16px / 2); overflow: visible; position: absolute; top: calc(50.57471264367819% - 16px / 2); width: 16px; }", ".framer-RAJLp .framer-ko2x01 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 10px); left: calc(50.57471264367819% - 10px / 2); overflow: visible; position: absolute; top: calc(50.57471264367819% - 10px / 2); width: 10px; }", ".framer-RAJLp.framer-v-bodyu .framer-1qdk16p { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 16px); }", ".framer-RAJLp.framer-v-bodyu .framer-1daot1g { height: var(--framer-aspect-ratio-supported, 20px); left: calc(50.57471264367819% - 20px / 2); top: calc(50.57471264367819% - 20px / 2); width: 20px; }", ".framer-RAJLp.framer-v-bodyu .framer-ko2x01 { height: var(--framer-aspect-ratio-supported, 12px); left: calc(50.57471264367819% - 12px / 2); top: calc(50.57471264367819% - 12px / 2); width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"E_NupjTuf":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CyE7CB3gO":"tint"}
 */
const Frameramn5bmL6D: React.ComponentType<Props> = withCSS(Component, css, "framer-RAJLp") as typeof Component;
export default Frameramn5bmL6D;

Frameramn5bmL6D.displayName = "Elements/Pulse";

Frameramn5bmL6D.defaultProps = {height: 16, width: 16};

addPropertyControls(Frameramn5bmL6D, {variant: {options: ["extZH8mqE", "E_NupjTuf"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, CyE7CB3gO: {defaultValue: "var(--token-265a51b5-06ea-48da-a42e-3d51fcd0756d, rgb(0, 153, 255)) /* {\"name\":\"Blue\"} */", title: "Tint", type: ControlType.Color}} as any)

addFonts(Frameramn5bmL6D, [])