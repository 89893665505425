export default {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "background": "#2d2b57",
    "fontWeight": "normal",
    "color": "#e3dfff"
  },
  "hljs-title": {
    "color": "#fad000",
    "fontWeight": "normal"
  },
  "hljs-name": {
    "color": "#a1feff",
    "fontWeight": "normal"
  },
  "hljs-tag": {
    "color": "#ffffff"
  },
  "hljs-attr": {
    "color": "#f8d000",
    "fontStyle": "italic"
  },
  "hljs-built_in": {
    "color": "#fb9e00"
  },
  "hljs-selector-tag": {
    "color": "#fb9e00",
    "fontWeight": "normal"
  },
  "hljs-section": {
    "color": "#fb9e00"
  },
  "hljs-keyword": {
    "color": "#fb9e00",
    "fontWeight": "normal"
  },
  "hljs-subst": {
    "color": "#e3dfff"
  },
  "hljs-string": {
    "color": "#4cd213"
  },
  "hljs-attribute": {
    "color": "#4cd213"
  },
  "hljs-symbol": {
    "color": "#4cd213"
  },
  "hljs-bullet": {
    "color": "#4cd213"
  },
  "hljs-addition": {
    "color": "#4cd213"
  },
  "hljs-code": {
    "color": "#4cd213"
  },
  "hljs-regexp": {
    "color": "#4cd213"
  },
  "hljs-selector-class": {
    "color": "#4cd213"
  },
  "hljs-selector-attr": {
    "color": "#4cd213"
  },
  "hljs-selector-pseudo": {
    "color": "#4cd213"
  },
  "hljs-template-tag": {
    "color": "#4cd213"
  },
  "hljs-quote": {
    "color": "#4cd213"
  },
  "hljs-deletion": {
    "color": "#4cd213"
  },
  "hljs-meta": {
    "color": "#fb9e00"
  },
  "hljs-meta-string": {
    "color": "#fb9e00"
  },
  "hljs-comment": {
    "color": "#ac65ff"
  },
  "hljs-literal": {
    "fontWeight": "normal",
    "color": "#fa658d"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  },
  "hljs-number": {
    "color": "#fa658d"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  }
};