export default {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "background": "#fdf6e3",
    "color": "#657b83"
  },
  "hljs-comment": {
    "color": "#93a1a1"
  },
  "hljs-quote": {
    "color": "#93a1a1"
  },
  "hljs-keyword": {
    "color": "#859900"
  },
  "hljs-selector-tag": {
    "color": "#859900"
  },
  "hljs-addition": {
    "color": "#859900"
  },
  "hljs-number": {
    "color": "#2aa198"
  },
  "hljs-string": {
    "color": "#2aa198"
  },
  "hljs-meta .hljs-meta-string": {
    "color": "#2aa198"
  },
  "hljs-literal": {
    "color": "#2aa198"
  },
  "hljs-doctag": {
    "color": "#2aa198"
  },
  "hljs-regexp": {
    "color": "#2aa198"
  },
  "hljs-title": {
    "color": "#268bd2"
  },
  "hljs-section": {
    "color": "#268bd2"
  },
  "hljs-name": {
    "color": "#268bd2"
  },
  "hljs-selector-id": {
    "color": "#268bd2"
  },
  "hljs-selector-class": {
    "color": "#268bd2"
  },
  "hljs-attribute": {
    "color": "#b58900"
  },
  "hljs-attr": {
    "color": "#b58900"
  },
  "hljs-variable": {
    "color": "#b58900"
  },
  "hljs-template-variable": {
    "color": "#b58900"
  },
  "hljs-class .hljs-title": {
    "color": "#b58900"
  },
  "hljs-type": {
    "color": "#b58900"
  },
  "hljs-symbol": {
    "color": "#cb4b16"
  },
  "hljs-bullet": {
    "color": "#cb4b16"
  },
  "hljs-subst": {
    "color": "#cb4b16"
  },
  "hljs-meta": {
    "color": "#cb4b16"
  },
  "hljs-meta .hljs-keyword": {
    "color": "#cb4b16"
  },
  "hljs-selector-attr": {
    "color": "#cb4b16"
  },
  "hljs-selector-pseudo": {
    "color": "#cb4b16"
  },
  "hljs-link": {
    "color": "#cb4b16"
  },
  "hljs-built_in": {
    "color": "#dc322f"
  },
  "hljs-deletion": {
    "color": "#dc322f"
  },
  "hljs-formula": {
    "background": "#eee8d5"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};